@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";

.ben-client-link {
  &:first-child {
    border-top: 2px solid $border-color;
  }

  &,
  &:hover {
    color: $primary-color;
  }

  padding-top: 13px;
  display: block;
  font-weight: bold;
}

@include breakpoint(md) {
  .ben-client-link:first-child {
    border-top: none;
  }
}
