@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-main-form-wrapper {
  max-width: 1200px;
  padding: 32px 32px 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $white-color;
  position: relative;
  @include box-shadow();

  .ben-form-container {
    max-width: 700px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 32px;

    .ben-form-table-container {
      margin-top: 32px;
      width: 100%;

      > span {
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.09px;
        line-height: 20px;
      }

      > .ant-btn {
        margin-top: 16px;
        float: right;
      }
    }

    .ant-form {
      margin: 0 auto;
      max-width: 486px;
      width: 100%;

      .ant-form-item:last-of-type {
        margin-bottom: 0;
      }

      .ant-form-item-label {
        font-size: 15px;
        letter-spacing: 0.09px;
        line-height: 16px;
        min-height: auto;
        margin-bottom: 3px;

        label {
          color: $secondary-color;
        }
      }

      .ant-input-affix-wrapper,
      .ant-input-number-affix-wrapper {
        height: 30px;

        &:hover {
          border-color: $input-color;
        }
      }
      .ant-input-affix-wrapper:hover {
        border-color: $input-color;
      }
    }
  }
}

@include breakpoint(md) {
  .ben-main-form-wrapper {
    padding: 0;
    box-shadow: none;
    width: 100%;

    .ben-form-container {
      margin-top: 24px;
    }
  }
}

.trustben-main-form-wrapper {
  box-shadow: none;
}
