@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";

.ben-edit-application-name-modal-title {
  font-family: $semi-bold-font;
  color: $primary-color;
}

.ben-edit-application-name-modal-button {
  min-width: 20%;
}
