@import "common/assets/styles/base/colors";

.ben-request-access-form {
  .request-access-trustben-button {
    display: block;
    margin: auto;
  }

  .request-access-info {
    color: $text-color;
    font-size: 13px;
  }
}

.ant-select-item.ant-select-item-option.ant-select-item-option-selected {
  .ben-tooltip {
    svg {
      fill: $white-color;
    }
  }
}
