@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/helper/mixins";

.trustben-form-header {
  @include flex($flexDirection: column, $alignItems: flex-start);
  width: 100%;
  max-width: 1100px;
  color: $secondary-color;

  .trustben-form-header-img {
    margin-bottom: 20px;
  }

  .trustben-form-header-title {
    font-family: $bold-font;
    color: $black-color;
    line-height: 38px;
    font-size: 30px;
  }

  .trustben-form-header-subtitle {
    font-family: $main-font;
    color: $black-color;
    font-size: 22px;
  }

  .trustben-form-header-hint {
    margin-top: 5px;
    color: $black-color;
    font-family: $main-font;
    font-size: 17px;
    letter-spacing: 0.04px;
    line-height: 26px;
  }

  .trustben-form-header-asset-name {
    margin-top: 40px;
    font-size: 26px;
    letter-spacing: -0.12px;
    line-height: 33px;
    text-align: center;
    color: $secondary-color;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: center;
  }
}

@include breakpoint(md) {
  .ben-form-header {
    padding: 0;

    .ben-form-header-subtitle {
      font-size: 21px;
      padding-bottom: 10px;
      border-bottom: 1px solid $light-gray-color;
    }

    .ben-form-header-hint {
      margin-top: 16px;
      font-size: 16px;
    }

    .ben-form-header-asset-name {
      margin-top: 24px;
      font-size: 19px;
      letter-spacing: -0.09px;
      line-height: 24px;
    }
  }
}
