@import "common/assets/styles/base/colors";

.ant-dropdown {
  .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
    background-color: $primary-color;

    &:hover {
      background-color: $primary-color;
    }

    .ant-dropdown-menu-title-content {
      color: $white-color;
    }
  }
}
