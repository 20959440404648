$primary-color: var(--primary-color);
$secondary-color: var(--secondary-color);
$dark-primary-color: var(--dark-primary-color);
$error-color: var(--error-color);

$white-color: var(--white-color);
$gray-color: var(--gray-color);
$gray-2-color: var(--gray-2-color);
$light-black-color: var(--light-black-color);
$light-blue-color: var(--light-blue-color);
$green-color: var(--green-color);
$black-color: var(--black-color);

$disabled-button-color: var(--disabled-button-color);
$button-hover-color: var(--button-hover-color);

$text-color: var(--text-color);
$gray-input-color: var(--gray-input-color);
$light-gray-color: var(--light-gray-color);
$border-color: var(--border-color);
$input-color: var(--input-color);
$light-gray-2-color: var(--light-gray-2-color);
$modal-gray-color: var(--modal-gray-color);
$gray-3-color: var(--gray-3-color);
$light-gray-3-color: var(--light-gray-3-color);

$received-status-color: var(--received-status-color);
$not-received-status-color: var(--not-received-status-color);
$pending-review-status-color: var(--pending-review-status-color);
$partially-received-status-color: var(--partially-received-status-color);
