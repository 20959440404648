@import "common/assets/styles/base/breakpoints";

.non-fund-assets-table-title {
  font-weight: bold;
}

.ben-non-fund-assets-table {
  .ant-table-thead {
    .ant-table-cell {
      padding: 16px 16px 7px 0;
    }
  }

  .ant-table-tbody {
    .ant-table-row .ant-table-cell {
      padding: 5px 16px 5px 0;
    }
  }
}

@include breakpoint(md) {
  .ben-non-fund-assets-table {
    .ant-table-tbody {
      .ant-table-row .ant-table-cell:last-child {
        padding-right: 0;
      }
    }
  }
}
