@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-add-new-client-modal-container {
  @include flex($flexDirection: column, $alignItems: center);
  margin: 5px 15px 30px 15px;

  .ben-add-new-client-modal-title {
    text-align: center;

    h1 {
      font-family: $semi-bold-font;
      color: $primary-color;
    }
  }

  p {
    align-self: flex-start;
  }

  .ben-add-new-client-modal-form {
    width: 100%;

    .ben-add-new-client-modal-form-item {
      margin: 0 115px 0 110px;

      .ant-form-item-label {
        width: 21%;
        label {
          position: absolute;
          font-weight: 400;
          top: 0px;
          font-size: 13px;
        }
      }
    }

    .ben-add-new-client-modal-form-item.ben-label-width {
      margin: 0 115px 0 62px;

      .ant-form-item-label {
        width: 30%;
      }
    }

    .ben-add-new-client-modal-form-checkbox {
      margin: -10px 0 0 183px;

      .ben-tooltip {
        font-size: 15px;
      }
    }

    .ben-add-new-client-modal-form-buttons {
      width: 47.5%;
      margin: 0 0 0 183px;
      .ant-form-item-control-input-content {
        @include flex();

        & > * {
          width: 45%;
        }
      }
    }
  }
}

@include breakpoint(md) {
  .ben-add-new-client-modal-form {
    & > * {
      margin: 0 !important;
    }

    .ant-form-item-label {
      margin-bottom: -15px;
    }

    .ben-add-new-client-modal-form-buttons {
      width: 100% !important;
    }
  }
}
