@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

.ben-confirmation-wrapper {
  background-color: $white-color;
  align-items: center;
  justify-items: center;
  text-align: center;
  padding: 70px 0;

  .ben-confirmation-upload-documents-section {
    padding: 24px 30%;

    h4 {
      border-bottom: 1px solid $light-gray-color;
      padding-bottom: 13px;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      margin: 0;
    }

    .ant-btn-primary {
      margin: 18px 0 32px 0;
    }
  }

  .ben-form-footer {
    max-width: 1100px;

    h2 {
      font-weight: bold;
      margin-bottom: 20px;
    }

    p {
      font-family: $italic-font;
      color: $secondary-color;
      font-size: 18px;
      margin-bottom: 8px;

      span {
        font-weight: bold;
        color: $secondary-color;
      }
    }
  }
}

@include breakpoint(md) {
  .ben-confirmation-wrapper {
    padding: 0;

    .ben-confirmation-upload-documents-section {
      padding: 0;
      margin-bottom: 48px;
    }

    .ben-confirmation-info-section {
      h2 {
        font-size: 14px;
      }

      p {
        font-size: 12px;
      }
    }
  }
}
