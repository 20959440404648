@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.ant-select {
  font-size: 16px;

  .ant-select-selector {
    font-size: 16px;
  }
}

.ant-select:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector.ant-select-selector.ant-select-selector {
  border-color: $input-color;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  )
  .ant-select-selector {
  border-color: $input-color;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: none !important;
}

.ant-select.select-client-name {
  width: 100%;

  .ant-select-selector {
    z-index: 10;
    font-size: 24px;
    font-family: $light-font;
    background: none;
    border: none;
    border-bottom: 2px solid $border-color !important;
    height: 35px;
    box-shadow: none !important;

    &:hover {
      border-bottom: 2px solid $border-color;
    }

    .ant-select-selection-item {
      color: $gray-color;
    }
  }

  .ant-select-arrow {
    z-index: 1;
    color: $secondary-color;
    font-size: 31px;

    svg {
      transition: transform 0.3s;
    }

    .anticon {
      position: absolute;
      top: -12px;
      right: -11px;
    }
  }

  .ant-select-clear {
    z-index: 10;
    font-size: 18px;
    background: none;
    width: 18px;
    height: 18px;
  }
}

.ant-select.select-client-name.ant-select-single.ant-select-focused {
  .ant-select-selector {
    border-bottom: 2px solid $border-color;
    box-shadow: none;
  }

  &.ant-select-open {
    .ant-select-arrow {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
}

.ant-select-dropdown {
  padding: 0;
  border-radius: 4px;

  .ant-select-item-option {
    color: $gray-color;
    font-size: 14px;
    font-family: $main-font;
    padding: 6px 10px;
    line-height: 16px;
    border-bottom: 2px solid $border-color;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: $input-color;
  }

  &:last-child {
    border: none;
  }
}

@include breakpoint(md) {
  .ant-select.select-client-name {
    .ant-select-selector {
      height: 33px;
      font-family: $bold-font;
      font-size: 20px;
      padding-left: 0;
    }
  }
}
