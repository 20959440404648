@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-altquote-page-collapse-offers.ant-collapse {
  padding-top: 5px;

  .ant-collapse-item {
    box-shadow: none;
    margin: 0;

    .ant-collapse-header {
      padding-left: 0;
      padding-right: 0;
    }

    .ant-collapse-content-box {
      padding: 0 0 0 22px;
    }
  }

  .ben-altquote-page-nested-collapse-offers.ant-collapse {
    .ant-collapse-header {
      &:first-child {
        padding: 0 0 4px 0;
      }
    }

    .ant-collapse-content-box {
      padding-left: 10px;
    }

    .ben-nested {
      padding-right: 30px;
    }

    .ben-nested-range {
      padding-right: 23px;
    }

    .ben-nested-col-1 {
      padding-right: 13px;
    }

    .ben-nested-col-2 {
      padding-right: 10px;
    }
  }

  .ben-altquote-page-panel-item {
    color: $gray-3-color;

    div:first-child {
      padding-left: 20px;
    }
  }
}

.ben-altquote-page-panel-item-option-row {
  color: $gray-3-color;
  margin-left: 20px;
  font-style: italic;
}

@include breakpoint(md) {
  .ben-altquote-page-collapse-offers.ant-collapse {
    .ant-collapse-header {
      display: block;

      .ant-collapse-expand-icon {
        width: 5%;
      }

      .anticon {
        margin-right: 0px !important;
      }
    }

    .ant-collapse-content-box {
      padding-left: 0 !important;
    }

    .ben-altquote-page-panel-item {
      padding-left: 0 !important;
      font-size: 13px;
      margin-left: 5%;
      margin-top: 5px;

      div:first-child {
        padding-left: 0 !important;
      }
    }
  }
}
