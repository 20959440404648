.ben-assets-documents-wrapper {
  max-width: 900px !important;
  width: 100%;
  margin-top: 40px;

  .assets-documents-bottom-info {
    width: fit-content;
    margin: 15px auto 0;
  }
}
