@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";


.alt-custody-asset-list-container {
    display: flex;
    flex-direction: column;
    width: unset;
    gap: 16px;
    .buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        align-items: center;
        &:nth-last-child(1) {
          flex: 1;
        }
        .totalNav {
            font-weight: bolder;
            flex: 1;
            font-size: 16px;
        }
    }
}

.altcustody-asset-list-item {
  display: flex;
  width: 100%;
  align-items: flex-start;
  .title-row {
    display: flex;
    align-items: baseline;
    flex: 2;
    gap: 4px;
    .asset-title {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      font-family: $bold-font;
      color: $primary-color;
    }
  }
  .data-column {
    flex: 1;
    .column-title {
      padding: 0;
      margin: 0;
      font-family: $bold-font;
    }
    .column-text {
      padding: 0;
      margin: 0;
    }
  }

  .ben-application-status {
    text-transform: capitalize;
  }
}

@include breakpoint(md) {
  p {
    font-size: 13px;
  }
}
