$border-style: 1px solid $border-color !important;

.ben-border-0 {
  border: 0 !important;
}

.ben-border-top-0 {
  border-top: 0 !important;
}

.ben-border-right-0 {
  border-right: 0 !important;
}

.ben-border-bottom-0 {
  border-bottom: 0 !important;
}

.ben-border-left-0 {
  border-left: 0 !important;
}

.ben-border {
  border: $border-style;
}

.ben-border-top {
  border-top: $border-style;
}

.ben-border-right {
  border-right: $border-style;
}

.ben-border-bottom {
  border-bottom: $border-style;
}

.ben-border-left {
  border-left: $border-style;
}
