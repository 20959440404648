@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-main-menu {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;

  .ben-navbar {
    display: flex;
    column-gap: 10px;

    .ben-link {
      font-family: $main-font;
      font-size: 14px;
      display: inline-block;
      padding: 0 3px;
      text-align: center;
      color: $gray-color;

      &:hover {
        font-weight: 600;
        color: $primary-color;
      }

      &.ben-active-link {
        font-weight: 600;
        position: relative;
        color: $primary-color;

        &:after {
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          content: "";
          border-radius: 2px;
          background: $primary-color;
        }
      }
    }
  }

  .ant-btn {
    margin-left: 25px;

    &.hidden {
      visibility: hidden;
    }
  }
}

@include breakpoint(lg) {
  .ben-main-menu {
    .ben-navbar {
      column-gap: 3px;
    }

    .ant-btn {
      margin-left: 15px;
    }
  }
}

@include breakpoint(md) {
  .ben-main-menu {
    left: 50%;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
    transform: translateX(-50%);

    .ben-navbar {
      align-items: center;
      flex-direction: column;
      width: 100%;
      row-gap: 10px;

      .ben-link {
        width: fit-content;
      }
    }

    .ant-btn {
      width: 70%;
      margin: 15px 0 0;
    }
  }
}
