@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/helper/mixins";

.ben-d-flex {
  display: flex !important;
}

.ben-d-block {
  display: block !important;
}

.ben-d-sm-block {
  @include breakpoint(md) {
    display: block !important;
  }
}

.ben-d-md-block {
  @include breakpoint(lg) {
    display: block !important;
  }
}
