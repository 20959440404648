@import "common/assets/styles/base/colors";
@import "common/assets/styles/helper/mixins";

.ben-modal-container {
  .ant-modal-header {
    padding: 30px 0;
    margin: 0 30px;

    .ant-modal-title {
      font-size: 20px;
      color: $dark-primary-color;
      font-weight: 600;
      line-height: 25px;
    }
  }

  .ant-modal-body {
    padding: 0 30px 30px;
  }

  .ant-modal-close {
    top: 32px;
    right: 30px;

    .ant-modal-close-x {
      width: 30px;
      height: 30px;
      line-height: 30px;

      .anticon {
        color: $primary-color;
        font-size: 20px;
      }
    }
  }

  .ben-modal-content {
    padding-bottom: 16px;
    padding-top: 23px;
    margin-bottom: 30px;
    border-bottom: 1px solid $border-color h4 {
      color: $text-color;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 24px;
    }

    p {
      line-height: 18px;
    }
  }
}

.ben-terms-agreement-modal-body {
  height: 60vh;
  margin-top: 15px;
  overflow-y: scroll;

  .ben-agreement-text-indent {
    text-indent: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ben-agreement-header {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
  }

  .ben-section-margin {
    margin: 0 10px 0 21px;
  }
}
