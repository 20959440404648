@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";


.altcustody-asset-list-container {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 280px);
  min-height: 100px;
  overflow: hidden;
  overflow-y: auto;
  .altcustody-asset-list-item {
    display: flex;
    cursor: pointer;
    align-items: center;
    gap: 8px;
    border-bottom: 1px solid $border-color;
    padding: 16px 0;
    transition: all ease-in-out .3s;
    font-family: $medium-font!important;

    &:hover {
      background:#fafafa;
    }

    .title-row {
      display: flex;
      align-items: baseline;
      cursor: pointer;
      gap: 4px;
      p {
        margin: 0;
      }
    }
    
    .data-cell {
      flex: 1;
      display: flex;
      p {
        font-size: 14px;
        padding: 0;
        margin: 0!important;
      }
    }
    
    .ben-application-status {
      text-transform: capitalize;
    }
    &.headers {
      position: sticky;
      top: 0;
      background: white;
      z-index: 1;
      p {
        color: $secondary-color;
        font-weight: bolder;
      }
    }
  }

  @include breakpoint(md) {
    p {
      font-size: 13px;
    }
  }

}

