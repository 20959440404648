@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

.ben-asset-documents-table {
  margin-top: 32px;

  .ant-table-thead {
    tr {
      .ant-table-cell {
        background-color: $modal-gray-color;
        border: none;
        color: $text-color;
        font-family: $main-font;
        font-weight: 300;
        height: 28px;
        padding-top: 0;
        padding-bottom: 0;

        &.ben-drug-n-drop-section {
          width: 35%;
          font-family: $bold-font;
          padding-left: 16px;
        }

        &.ben-file-name-section {
          width: 30%;
        }

        &.ben-date-provided-section {
          width: 20%;
          padding-right: 0;
        }

        &.ben-document-password-section {
          width: 35%;
          padding: 0;
          white-space: normal;
        }

        &.ben-action-section {
          width: 12%;
          padding: 0;
        }
      }
    }
  }

  .ant-table-body {
    overflow-y: auto !important;

    .ant-table-tbody {
      .ant-table-measure-row {
        td {
          padding: 0 !important;
        }
      }

      .ant-table-row {
        .ant-table-cell {
          border-bottom: none;
          height: 100%;
          padding-top: 2px;
          padding-bottom: 2px;

          &.ben-drug-n-drop-section {
            padding: 0;
          }

          .ant-icon {
            color: $primary-color;
          }

          &.ben-file-name-section {
            padding-right: 0;

            div {
              display: contents;
            }
          }

          .ben-download-file-icon {
            color: $primary-color;
            font-size: 16px;
          }

          p {
            display: inline;
            margin-left: 8px;
            font-size: 15px;
            font-weight: 300;
            font-family: $main-font;
          }
        }
      }
    }
  }

  .ant-upload {
    &.ant-upload-drag {
      border: 1px dashed #979797;
      background-color: inherit;
      min-width: 190px;

      p {
        margin: 0;
        font-size: 15px;
        color: #767676;
      }
    }

    &.ant-upload-drag:hover {
      border-color: $primary-color;
    }

    &.ant-upload-drag:not(.ant-upload-disabled):hover {
      border-color: $primary-color;
    }
  }
}
