@import "common/assets/styles/base/colors";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/breakpoints";

.divider-with-or-container {
  @include flex($flexDirection: column);
  align-self: stretch;
  margin: 0 30px;

  .divider-line {
    flex-grow: 1;
    border-color: $gray-2-color;
  }

  .divider-text {
    color: $light-gray-3-color;
    font-weight: bold;
    padding: 5px 0;
  }
}

@include breakpoint(md) {
  .divider-with-or-container {
    @include flex($flexDirection: row);
    margin: 20px 0;

    .divider-line {
      min-width: 0;
    }

    .divider-text {
      padding: 0 10px;
    }
  }
}
