@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

.ben-asset-documents-list-mobile {
  text-align: left !important;
  margin-bottom: 30px;

  h4 {
    background-color: $modal-gray-color;
    font-size: 15px;
    font-weight: bold;
    padding-left: 8px;
  }

  .ant-upload {
    cursor: pointer;
    text-align: center;
    display: block;
    margin: 24px auto 18px auto;
    color: $primary-color;
    font-weight: bold;

    .ben-icon {
      font-size: 18px;
    }

    p {
      margin-bottom: 0;
      font-size: 15px;
    }
  }

  .ben-files-list-item {
    font-size: 15px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    p {
      margin-left: 5px;
      margin-bottom: 0;

      form {
        button {
          width: auto;
        }
      }
    }

    .anticon {
      align-self: center;
    }

    .ben-download-file-icon {
      margin-left: auto;
      margin-right: 10px;
      color: $primary-color;
      font-size: 16px;
    }

    .ben-delete-file-btn {
      display: block;
      align-self: center;
      margin-right: 0;
      color: $primary-color;
    }
  }
}
