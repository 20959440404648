@import "common/assets/styles/base/colors";

.ben-phone-input {
  width: 100%;

  .ant-input-number-suffix {
    display: none;
  }

  .ant-select.ant-select.ant-select.ant-select-focused .ant-select-selector {
    border-color: transparent;
  }

  .ant-input-number-group-addon .ant-select-open .ant-select-selector,
  .ant-input-number-group-addon .ant-select-focused .ant-select-selector {
    color: inherit;
  }

  .ant-select-selection-item {
    display: flex;
  }

  .ant-input-number-input-wrap {
    height: 100%;

    .ant-input-number-input {
      height: 100%;
    }
  }

  &.small {
    .ant-input-number-affix-wrapper {
      height: 31px;
    }
  }

  &:not(.small) {
    .ant-input-number-input-wrap {
      padding: 4px 0;
    }
  }
}

.ben-flag-icon {
  width: 20px;
  height: auto;
}
