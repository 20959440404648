@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";

.altquote-email-section {
  .altquote-email-section-title {
    text-align: center;
    font-family: $bold-font;
    color: $black-color;
    line-height: 38px;
    font-size: 22px;
  }

  .altquote-email-section-text {
    font-size: 15px;
  }
}
