@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

// Transformation
.ben-text-lowercase {
  text-transform: lowercase !important;
}

.ben-text-uppercase {
  text-transform: uppercase !important;
}

.ben-text-capitalize {
  text-transform: capitalize !important;
}

// Alignment
.ben-text-left {
  text-align: left !important;
}

.ben-text-right {
  text-align: right !important;
}

.ben-text-center {
  text-align: center !important;
}

.ben-word-break-all {
  word-break: break-all;
}

.ben-white-space-break-spaces {
  white-space: break-spaces !important;
}

.ben-important-text {
  font-weight: 600;
  text-decoration: underline;
}

.ben-underline-text {
  text-decoration: underline;
}

.ben-form-footer-info {
  padding: 12px 0;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0.13px;
  line-height: 27px;
  font-family: $italic-font;
  color: $secondary-color;
}

@include breakpoint(md) {
  .ben-form-footer-info {
    padding: 8px 0;
    font-size: 14px;
    font-style: italic;
    letter-spacing: 0.09px;
    line-height: 18px;
  }
}
