@import "common/assets/styles/base/colors";
@import "common/assets/styles/helper/mixins";

.ben-required-field {
  label {
    &:after {
      @include transition;
      display: inline-block !important;
      margin-right: 4px;
      color: $light-blue-color;
      font-size: 14px;
      line-height: 1;
      content: "*";
    }
  }
}
