.custody-account-selector {
    display: flex;
    font-size: 12px;
    margin-right: -96px;
    margin-left: -96px;
    gap: 8px;
    align-items: flex-end;
    .column{
        flex: 1;
        .title {
        }
        .data {
            font-weight: 700;
        }
    }
}
