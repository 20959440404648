@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.fund-assets-table-title {
  font-weight: bold;
}

.ben-fund-assets-table {
  .ant-table-thead {
    .ant-table-cell {
      padding: 16px 16px 7px 0;
    }
  }

  .ant-table-tbody {
    .ant-table-row .ant-table-cell {
      padding: 5px 16px 5px 0;
      white-space: normal;
    }
  }
}

.ben-fund-assets-text {
  font-family: $semi-bold-font;
  color: $secondary-color;
  font-size: 15px;
}

@include breakpoint(md) {
  .ben-fund-assets-table {
    .ant-table-tbody {
      .ant-table-row .ant-table-cell:last-child {
        padding-right: 0;
      }
    }
  }
}
