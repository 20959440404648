@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";


.ben-application-item {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  margin-bottom: 16px;

  b {
    font-family: $bold-font;
  }
  .ben-todo-info {
    display: flex;

    .anticon {
      margin-right: 5px;
      position: relative;
      top: 3px;
    }
  }

  .ben-application-item-info {
    display: grid;
    grid-template-columns: 3fr 2fr;
    column-gap: 8px;

    .ben-application-name-and-date {
      p {
        margin-bottom: 0;
        font-size: 12px;
      }

      .ben-application-name {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: bold;
        font-family: $bold-font;
        color: $primary-color;
      }
    }

    .ben-application-status {
      text-transform: capitalize;
    }
  }
}

@include breakpoint(md) {
  .ben-application-item {
    margin-top: 35px;
    display: block;

    .ant-btn {
      margin-top: 16px;
    }

    .ben-application-item-info {
      width: 100%;
      display: block;

      .ben-application-status {
        margin-top: 12px;
      }
    }

    p {
      font-size: 13px;
    }
  }
}
