@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.custody-account-picker-container {
  @include flex($flexDirection: column, $justifyContent: center);
  width: 300px;

  p {
    color: $secondary-color;
    font-weight: 600;
    margin-bottom: 0;
  }

  .ant-select {
    width: 100%;
  }
}
