@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/helper/mixins";

.ben-apply-for-liquidity-button-container {
  @include flex($justifyContent: flex-start);
  margin-top: 10px;

  .ant-btn {
    margin-right: 10px;
  }

  &:last-child {
    font-family: $semi-bold-font;
    color: $gray-3-color;
  }
}
