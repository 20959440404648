@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";

.trustben-footer {
  background-color: $secondary-color;
  padding: 40px;
  color: $white-color;
}

.trustben-footer-columns-section {
  @include flex($alignItems: stretch, $justifyContent: space-between);

  .trustben-footer-column {
    list-style: none;
    margin: 0;
    padding: 0;

    .trustben-footer-column-item {
      margin-top: 12px;

      & a {
        color: inherit;
      }

      & a:hover {
        border-bottom: 1px solid;
      }

      &:first-child {
        color: #0d88a5;
        font-weight: bold;
        font-size: 16px;
      }
    }
  }
}

.trustben-footer-support-section {
  @include flex();
  border-bottom: 1px solid;
  padding-bottom: 40px;
  margin: 70px 0;

  .trustben-footer-social-media {
    svg {
      width: 2.7em;
      height: 2.7em;
      margin-right: 10px;
    }
  }
}

.trustben-footer-text-section {
  @include flex();
  font-size: 12px;

  .trustben-footer-link {
    color: inherit;
  }

  .trustben-footer-text-section-left-block {
    margin-right: 100px;
  }
}

@include breakpoint(md) {
  .trustben-footer-columns-section {
    @include flex($flexDirection: column, $alignItems: stretch);
  }

  .trustben-footer-text-section {
    @include flex($flexDirection: column);

    .trustben-footer-text-section-left-block {
      margin-right: 0;
    }
  }
}
