.ben-logo {
  display: inline-block;
  vertical-align: top;

  img {
    vertical-align: top;
    max-width: 100%;
    max-height: 100%;
  }
}
