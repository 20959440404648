@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/breakpoints";

.ben-advisor-client-applications-start-section-checkbox {
  display: inline-flex;
  justify-content: flex-end;
  width: 50%;
  vertical-align: bottom;
  padding-bottom: 8px;
}

@include breakpoint(md) {
  .ben-advisor-client-applications-start-section-checkbox {
    @include flex($justifyContent: center, $flexDirection: column);
    width: 100%;
  }
}
