@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/helper/mixins";

.ant-collapse {
  border: none;
  background: none;

  .ant-collapse-item {
    @include box-shadow();
    border: none;
    background: $white-color;
    margin-bottom: 6px;

    .ant-collapse-header {
      display: flex;
      align-items: center;
      color: $secondary-color;
      font-size: 18px;
      font-family: $semi-bold-font;
      padding: 10px 22px;

      .ant-collapse-arrow {
        stroke: $secondary-color;
        stroke-width: 130;
      }
    }

    .ant-collapse-extra {
      margin-left: auto;
    }

    .ant-collapse-content {
      border: none;
      background: none;

      .ant-collapse-content-box {
        padding: 0px 20px 16px 46px;

        .content {
          border-top: 2px solid $border-color;
          padding-top: 16px;
        }
      }
    }
  }
}

.ben-collapse.ant-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      border: none;
      color: $primary-color;
      font-family: $bold-font;

      .ant-collapse-arrow {
        font-family: monospace;
        font-size: 16px;
        margin-right: 4px;
        padding-bottom: 0;
        font-weight: 900;
      }
    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-top: 12px;
        padding-bottom: 0;
      }
    }
  }
}

@include breakpoint(md) {
  .ant-collapse {
    .ant-collapse-item {
      box-shadow: none;
      background: none;
      margin-bottom: 10px;

      .ant-collapse-header {
        font-size: 16px;
        background: none;
        border-bottom: 1px solid $border-color;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 3px;
        padding-top: 3px;
      }

      .ant-collapse-content {
        .ant-collapse-content-box {
          padding-left: 0px;
          padding-right: 0px;

          .content {
            border: none;
          }
        }
      }
    }
  }
}
