@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

.ben-upload-documents-form {
  position: relative;
  width: 100%;

  .ant-table-tbody .ant-table-placeholder .ant-table-cell {
    padding: 0;
  }

  .ant-collapse-item {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0) !important;
    border-radius: 0 !important;
    margin-bottom: 32px;

    .ant-collapse-header {
      background-color: inherit;
      border-bottom: 1px solid $border-color;
      padding: 0 !important;
      font-size: 15px !important;
      color: $primary-color !important;

      .ant-collapse-expand-icon {
        display: flex;
        padding: 0;
      }

      .ant-collapse-arrow {
        stroke: $primary-color !important;
        font-size: 8px !important;
        margin-right: 3px !important;
      }
    }

    .ant-collapse-content-box {
      text-align: left;
      padding: 16.5px 69.5px 0 65.5px !important;
      font-size: 15px;

      ul {
        margin-bottom: 0;
        padding-left: 30px;

        li {
          padding-left: 5px;
        }
        li:hover{
          background-color: transparent;
          color:black;

        }
      }
    }
  }
}

@include breakpoint(md) {
  .ben-upload-documents-form {
    .ant-collapse-item {
      .ant-collapse-content-box {
        text-align: left;
        padding: 0 !important;
        font-size: 15px;

        ul {
          margin-bottom: 0;

          li {
            padding-left: 0px;
          }
        }
      }
    }
  }
}
