@import "common/assets/styles/helper/mixins";

.ben-no-assets {
  display: flex;
  column-gap: 24px;
  align-items: center;
  font-size: 16px;
}

.ben-review-assets-page-spinner {
  width: 100%;

  .ant-spin-container {
    width: 100%;
    @include flex($flexDirection: column);
  }

  .ben-form-container.ben-form-container {
    max-width: 800px;
  }
}
