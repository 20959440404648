@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/helper/mixins";

.ben-tooltip {
  margin-left: 6px;
  font-size: 14px;
  color: $primary-color;
  font-family: $bold-font;

  &.dark {
    color: $secondary-color;
  }

  &:hover {
    cursor: pointer;
  }
}

.ben-tooltip-content {
  max-width: 300px;

  .ant-tooltip-arrow {
    display: none;
  }

  .ant-tooltip-inner {
    @include box-shadow-3();
    background-color: $white-color;
    color: $gray-color;
    border-radius: 3px;
    padding: 8px 20px;
  }
}
