@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";

.ben-documents-status-table {
  margin-top: 30px;

  .ant-table-tbody {
    .ant-table-measure-row {
      td {
        padding: 0 !important;
      }
    }
  }

  .ant-table-body {
    overflow-y: auto !important;
  }

  .ant-table-tbody > tr > td {
    padding: 10px 18px !important;
    white-space: normal !important;
  }

  .ant-table-thead > tr > th {
    white-space: normal !important;
    vertical-align: bottom;
  }
}

.ben-document-status-table-cell-received {
  background-color: $received-status-color;
}

.ben-document-status-table-cell-not-received {
  background-color: $not-received-status-color;
}

.ben-document-status-table-cell-pending-review {
  background-color: $pending-review-status-color;
}

.ben-document-status-table-cell-partially-received {
  background-color: $partially-received-status-color;
}

.ant-table-tbody > tr.ant-table-row:hover > .ben-document-status-table-cell-received {
  background-color: $received-status-color;
}

.ant-table-tbody > tr.ant-table-row:hover > .ben-document-status-table-cell-not-received {
  background-color: $not-received-status-color;
}

.ant-table-tbody > tr.ant-table-row:hover > .ben-document-status-table-cell-pending-review {
  background-color: $pending-review-status-color;
}

.ant-table-tbody > tr.ant-table-row:hover > .ben-document-status-table-cell-partially-received {
  background-color: $partially-received-status-color;
}

.ben-documents-status-table-title {
  font-family: $semi-bold-font;
}

.ben-documents-status-table-documents-wrapper {
  font-family: $medium-font;

  .ben-documents-status-table-document-name {
    color: $secondary-color;
  }
}

.ant-popover-inner-content {
  a {
    color: $secondary-color;
  }
}
