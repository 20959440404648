@import "common/assets/styles/base/colors";

.ant-radio-wrapper {
  font-size: 12px;
  color: $gray-color;

  .ant-radio {
    align-self: flex-start;
    top: 0.17em;
  }
}

.ant-radio-wrapper:not(.ant-radio-wrapper-disabled) {
  .ant-radio:hover .ant-radio-inner,
  &:hover .ant-radio-inner {
    border-color: $primary-color;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: $primary-color;
  }

  &.ant-radio-wrapper-checked {
    color: $primary-color;

    .ant-radio-inner {
      border-color: $primary-color;
    }

    .ant-radio-inner::after {
      background-color: $primary-color;
    }
  }
}
