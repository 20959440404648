@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

body {
  line-height: 1.5715;
}

a {
  text-decoration: none;
}

h1 {
  font-size: 24px;
  color: $gray-color;
  font-weight: 300;
  font-family: $light-font;
}

h2 {
  font-size: 20px;
  font-family: $italic-font;
  color: $secondary-color;
}

h2.modal {
  font-family: $semi-bold-font;
  color: $secondary-color;
  font-size: 19px;
  line-height: 20px;
}

h3 {
  font-size: 20px;
  color: $secondary-color;
  font-weight: bold;
  line-height: 22px;
}

h3.modal {
  font-family: $bold-font;
  font-size: 21px;

  @include breakpoint(md) {
    font-size: 17px;
  }
}

h4 {
  font-size: 18px;
  font-family: $semi-bold-font;
  color: $gray-color;
  line-height: 22px;
}

h5 {
  font-size: 14px;
  font-family: $bold-font;
  color: $secondary-color;
}

span.text {
  font-size: 15px;
  line-height: 20px;
  color: $gray-color;
}

span.info {
  font-size: 21px;
  line-height: 27px;
  font-family: $italic-font;
  color: $secondary-color;

  @include breakpoint(md) {
    font-size: 14px;
    line-height: 18px;
  }
}

.link {
  font-size: 14px;
  font-family: $semi-bold-font;
}

.anticon {
  display: inline-block;
}
