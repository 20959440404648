@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/utilities/borders";
@import "common/assets/styles/base/fonts";

.ben-modal-request-formal-proposal {
  .ant-modal-footer {
    border: none;
    @include flex();
    margin: 0 28px;
    padding-bottom: 50px;

    .ant-btn-primary {
      width: 170px;
    }
  }
}

.ben-modal-request-formal-proposal-input {
  .ant-input-wrapper.ant-input-group {
    width: 55%;
    margin: 15px auto;
  }
}

.ben-modal-request-formal-proposal-table {
  color: $gray-3-color;
  margin: 5px 20px;

  h1 {
    text-transform: uppercase;
    text-align: center;
    color: $primary-color;
    font-family: $semi-bold-font;
  }

  p {
    padding-left: 19px;
  }

  .ant-table-tbody > tr > td {
    color: $primary-color;
  }

  .ant-table-footer {
    padding: 10px 19px;
    background: none;
    border-bottom: $border-style;
  }

  .ben-modal-request-formal-proposal-table-checkbox > span:last-child {
    color: $gray-3-color;
    padding-left: 19px;
  }
}
