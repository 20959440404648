@font-face {
  font-family: IBM Plex sans Regular;
  src: url("../../fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: IBM Plex sans Light;
  src: url("../../fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: IBM Plex sans Bold;
  src: url("../../fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: IBM Plex sans SemiBold;
  src: url("../../fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: IBM Plex sans Italic;
  src: url("../../fonts/IBM_Plex_Sans/IBMPlexSans-Italic.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: IBM Plex sans Medium;
  src: url("../../fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf") format("truetype");
  font-display: swap;
}

$main-font: IBM Plex Sans Regular;
$light-font: IBM Plex Sans Light;
$bold-font: IBM Plex Sans Bold;
$italic-font: IBM Plex Sans Italic;
$semi-bold-font: IBM Plex Sans SemiBold;
$medium-font: IBM Plex sans Medium;
