@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-panel {
  background: $white-color;
  border-radius: 8px;
  padding: 31px 27px;

  @include breakpoint(sm) {
    padding: 0;
  }
}

.ben-panel-header {
  border-bottom: 1px solid $border-color;
  padding-bottom: 20px;
  margin-bottom: 28px;

  h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    color: $dark-primary-color;
  }
}

.trustben-panel-header {
  text-align: center;

  h3 {
    color: unset;
  }
}
