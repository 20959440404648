@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";

.ben-compact-todo {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 160px;
  column-gap: 8px;
  align-items: center;

  .ben-todo-info {
    display: flex;

    .anticon {
      margin-right: 5px;
      position: relative;
      top: 3px;
    }
  }

  .ant-btn.ben-todo-action {
    white-space: normal;
    height: fit-content;
  }
}

@include breakpoint(md) {
  .ben-compact-todo {
    width: 100%;
    display: block;
    position: center;

    .ben-todo-info {
      width: 100%;
      display: flex;
    }

    .ant-btn.ben-todo-action {
      position: relative;
      max-width: 100%;
      white-space: normal;
      height: auto;
      margin-top: 0 !important;
    }

    p {
      font-size: 13px;
    }
  }
}
