.ben-error-indicator {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ben-error-indicator-title {
  font-size: 1.7rem;
}
