@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-header {
  position: fixed;
  z-index: 1001;
  width: 100%;
  height: 93px;
  background: $white-color;
  box-shadow: 0 2px 4px 0 rgba(190, 190, 190, 0.35);

  .ben-container {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    margin: 0;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    & > div {
      max-width: 1200px;
      position: relative;
      width: 100%;
      display: inline-flex;
    }
  }
}

@include breakpoint(lg) {
  .ben-header {
    height: 58px;
  }
}

@include breakpoint(md) {
  .ben-header {
    .ben-hamburger {
      width: 30px;
      padding: 7px;
      margin-right: 14px;
    }
  }
}

.trustben-header {
  height: 63px;
  background-color: #f6f6f6;
}
