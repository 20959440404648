@import "common/assets/styles/base/breakpoints";

.select-fund-table-container {
  width: 100%;

  .select-fund-table {
    .ant-table-thead .ant-table-cell {
      overflow: initial;
    }
  }
}
