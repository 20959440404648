@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.ben-collapse-title-container {
  @include flex();
  width: 100%;

  .ben-collapse-title-count {
    padding-left: 5px;
    color: $gray-color;
    font-family: $main-font;
  }

  .ben-collapse-title-price {
    white-space: nowrap;
  }
}
