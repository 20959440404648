@use "helper/mixins";
@import "antd/dist/reset.css";
@import "./base/colors";
@import "./base/fonts";
@import "./antd-custom";
@import "./custom-antd";
@import "./utilities";
@import "./components";
@import "./icon-styles/iconStyles";

body {
  margin: 0;
  font-family: $main-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ben-container {
  max-width: 1200px + 48px;
  margin: 0 auto;
  width: 100%;
  padding: 0 24px;
}
