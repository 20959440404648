@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/helper/mixins";

.snapshots-modal {
  .ant-modal-title {
    text-align: center;
    color: $secondary-color;
  }

  .list-header-container {
    @include flex();
  }

  .list-title {
    color: $secondary-color;
    font-family: $semi-bold-font;
  }

  .list-item {
    @include flex();
  }

  .list-item-value {
    color: $primary-color;
    font-family: $semi-bold-font;
  }
}
