@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/breakpoints";

.ben-form-container {
  .ant-tabs-tabpane {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ant-tabs {
    width: 100%;

    > .ant-tabs-nav {
      width: fit-content;
    }

    .ant-tabs-nav-operations {
      display: none;
    }

    .ant-tabs-content-holder {
      padding-bottom: 0;
      box-shadow: 0 -10px 10px -10px rgba(0, 0, 0, 10%);
    }
  }
}

@include breakpoint(md) {
  .ben-form-container {
    h1 {
      font-size: 21px;
      border-bottom: 1px solid $light-gray-color;
      padding: 0 18px 14px 18px;
      margin-bottom: 14px;
    }
  }
}
