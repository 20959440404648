@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.ben-table-cell-fund-manager {
  font-family: $semi-bold-font;
  font-size: 15px;
  line-height: 19px;
}

.ben-table-cell-fund-name {
  font-size: 15px;
  font-family: $main-font;
  line-height: 19px;
}
