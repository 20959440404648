@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/breakpoints";

.ant-modal-root {
  .ant-modal-wrap,
  .ant-modal-mask {
    z-index: 1002;
  }
}

.ant-modal-content {
  border-radius: 3px;
}

.ant-modal-close-x {
  color: $primary-color;
  stroke: $primary-color;
  stroke-width: 50;
  stroke-linejoin: bevel;
}

.gray-modal {
  .ant-modal-body {
    padding: 53px 20px;
    background: $modal-gray-color;
  }
}

@include breakpoint(sm) {
  .ant-modal {
    margin-left: 15px;
    margin-right: 15px;
    width: auto !important;
  }

  .ant-modal-close-x {
    stroke-width: 60;
    font-size: 23px;
  }

  .gray-modal {
    .ant-modal-body {
      padding: 43px 8px;
    }

    .ant-modal-close-x {
      height: 43px;
      line-height: 43px;
      width: 36px;
    }
  }
}
