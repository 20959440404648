:root {
  --error-color: #e6172c;

  --white-color: #ffffff;
  --gray-color: #373737;
  --gray-2-color: #d8d8d8;
  --light-black-color: #4a4a4a;
  --green-color: #0a853d;
  --black-color: #222222;

  --disabled-button-color: #a7a6a6;

  --text-color: var(--light-black-color);
  --gray-input-color: #bcbcbc;
  --light-gray-color: #d5d5d5;
  --border-color: #d6d8db;
  --light-gray-2-color: #f8f8f8;
  --modal-gray-color: #f0f2f5;
  --gray-3-color: #767676;
  --light-gray-3-color: #a8a7a7;
}

/* These colors below are set in the "useWhiteLabel" React hook */
/* --primary-color: #0a8725;
--secondary-color: #10c455ec;
--dark-primary-color: #02611a;
--light-blue-color: #70ff8d;;
--button-hover-color: #70ff8d;;
--input-color: #70ff8d;
--received-status-color: #90ff6480;
--pending-review-status-color: #ffff0080;
--partially-received-status-color: #ffff0080;
--not-received-status-color: #FFFF0080; */
