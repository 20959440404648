@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";

.ben-modal-apply-for-liquidity {
  @include flex($justifyContent: center);
  flex-direction: column;

  .ben-cards-container {
    @include flex($justifyContent: space-between);
    width: 100%;
    padding-bottom: 8px;
  }

  .ben-card-container {
    @include flex($justifyContent: center);
    width: 100%;
    padding-bottom: 8px;
  }

  .ben-types-of-assets-considered-container {
    text-align: right;
    width: 100%;
  }

  .ben-info-container {
    width: 80%;

    .ben-info-text {
      padding: 23px 55px;
    }
  }

  .ben-modal-apply-for-liquidity-footer {
    margin-top: 16px;
  }

  .ben-form-footer {
    p {
      margin: 0;

      span {
        font-weight: bold;
      }
    }
  }
}

@include breakpoint(md) {
  .ben-modal-apply-for-liquidity {
    @include box-shadow();
    background: $white-color;
    padding-bottom: 24px;

    .ben-modal-new-liquidity-request {
      padding: 20px 10px 24px;
    }

    .ben-cards-container {
      flex-direction: column;
      justify-content: center;
      padding-bottom: 0;
    }

    .ben-types-of-assets-considered-container {
      text-align: center;
      line-height: 12px;
    }

    .ben-info-container {
      width: 100%;
    }

    .ben-modal-apply-for-liquidity-footer {
      margin-top: 32px;
    }
  }
}
