@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-altquote-page-panel-header-row {
  width: 100%;

  .ben-panel-header-offer-options {
    font-size: 15px;
    color: $primary-color;
  }

  .ben-panel-header-text {
    font-size: 15px;
    color: $gray-3-color;
    font-family: $main-font;
  }
}

@include breakpoint(md) {
  .ben-altquote-page-panel-header-row {
    width: 100%;

    .ben-panel-header-offer-options {
      font-size: 13px !important;
    }

    .ben-panel-header-text {
      font-size: 13px !important;
    }
  }
}
