@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";

.ben-form-nav-buttons-wrapper {
  margin-top: 40px;

  .ben-form-nav-buttons {
    @include flex();
    width: fit-content;
    margin: 0 auto;

    button {
      min-width: 100px;
    }

    button:not(:last-child) {
      margin-right: 15px;
    }
  }
}

@include breakpoint(md) {
  .ben-form-nav-buttons-wrapper {
    width: 100%;
    border-top: 1px solid $light-gray-color;
    margin-top: 36px;

    .ben-form-nav-buttons {
      padding-top: 15px;
      width: 100%;

      &.submit-only {
        button {
          width: 100%;
          margin: 0;
        }
      }

      button {
        max-width: 100%;
        width: calc(50% - 8px)
      }
    }
  }
}
