@import "common/assets/styles/base/breakpoints";

.ben-form {
  margin: 40px;
}

.ben-form-item {
  .ant-form-item-label {
    width: 200px;
  }
}

.ben-form-label-field {
  .ant-form-item-row {
    padding: 7px 0 18px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 0;
  }

  &.ant-form-item {
    &:before,
    &:after {
      display: none;
    }

    .ant-form-item-control {
      flex: 1 1;
      min-width: 69%;
      max-width: 69%;
      width: 69%;

      @include breakpoint(sm) {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
      }
    }
  }
}

.ben-form-field-col-2 {
  .ben-form-label-field {
    &.ant-form-item {
      .ant-form-item-control {
        min-width: 50%;
        max-width: 50%;
        width: 50%;
      }
    }
  }
}

.ben-form-cols-2 {
  column-count: 2;
  column-gap: 5px;
  margin: 0 -14px 0 -15px !important;
  position: relative;

  @include breakpoint(sm) {
    column-count: 1;
  }

  > div {
    padding: 0 14px 0 15px;
    break-inside: avoid-column;
  }
}

.ben-form-actions {
  @include breakpoint(md) {
    display: flex;
    flex-direction: column-reverse;
  }
}
