@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/helper/mixins";

.ant-tabs {
  overflow: visible;

  .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-nav-wrap {
    overflow: visible !important;

    .ant-tabs-nav-list {
      @include box-shadow-tabs();
      background-color: rgba(34, 60, 80, 0.2);

      .ant-tabs-tab {
        background-color: $light-gray-2-color;

        font-family: $semi-bold-font;
        font-size: 14px;
        color: $primary-color;

        &.ant-tabs-tab-active {
          background: $white-color;
        }

        .ant-tabs-tab-btn {
          padding: 13px 20px 15px 20px;
        }

        .ant-tabs-tab-btn:focus,
        .ant-tabs-tab-btn:active {
          color: $primary-color;
        }

        &.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: $gray-color;
        }
      }
    }
  }

  .ant-tabs-content-holder {
    padding: 32px;
    background-color: $white-color;
    @include box-shadow-tabs();
  }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0;
  border: 0;
  padding: 0;
}

.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  color: $primary-color;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  display: none;
}

@include breakpoint(lg) {
  .ant-tabs {
    .ant-tabs-tab-btn {
      padding: 12px 4px !important;
    }

    .ant-tabs-tab {
      font-size: 13px;
    }
  }
}

@include breakpoint(md) {
  .ant-tabs {
    .ant-tabs-content-holder {
      padding: 24px 0;
    }
  }
}
