@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.ant-table-wrapper {
  width: 100%;

  .ant-table table {
    table-layout: fixed !important;
  }

  .ant-table-thead {
    .ant-table-cell {
      padding: 10px 19px;
      background: none;
      font-size: 14px;
      font-family: $semi-bold-font;
      color: $secondary-color;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      font-size: 14px;
      color: $gray-color;
      font-family: $medium-font;

      .ant-table-cell {
        padding: 10px 19px;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
          padding-left: 0;
        }
      }
    }
  }
}
