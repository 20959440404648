@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/fonts";

.ben-button.ant-btn {
  background: none;
  height: 28px;
  border: 1px solid $primary-color;
  padding-top: 2px;
  border-radius: 0;
  font-family: $semi-bold-font;
  font-weight: bold;
  text-shadow: none;
  font-size: 14px;
  letter-spacing: 0;
  color: $primary-color;

  &.ant-btn-default:hover,
  &.ant-btn-default:active {
    background: none;
    color: $secondary-color;
    border: 1px solid $secondary-color;
  }

  span {
    padding-left: 5px;
    padding-right: 5px;
  }

  &.ant-btn-primary {
    background: $primary-color;
    color: $white-color;

    &:hover,
    &:active {
      border-color: $secondary-color;
      background: $secondary-color;
      border-color: $secondary-color;
    }
  }

  &.ant-btn-link {
    border: none;
    font-size: 16px;
    padding: 0;
    outline: none;

    &:hover,
    &:active {
      color: $secondary-color;
    }

    span {
      padding: 0;

      &:hover {
        color: $secondary-color;
        text-decoration: 2px underline $primary-color;
      }
    }
  }

  &.ant-btn-text {
    border: none;
    color: $primary-color;
    padding: 0;
    outline: none;

    &:hover,
    &:active {
      color: $primary-color;
      background: none;
    }

    span {
      padding: 0 0 0 0;

      &:hover {
        color: $primary-color;
        text-decoration: 2px underline $primary-color;
      }
    }

    &:hover {
      background-color: $white-color;
    }
  }

  &.ant-btn-lg {
    height: 50px;
    font-size: 18px;

    @include breakpoint(md) {
      height: 32px;
      font-size: 16px;
    }
  }

  &[disabled],
  &[disabled]:hover {
    color: $white-color;
    background-color: $disabled-button-color;
    border-color: $disabled-button-color;
    text-shadow: none;
    box-shadow: none;
  }

  &.big {
    height: 32px;
    font-size: 16px;
    padding-top: 3px;
  }

  &.small {
    font-size: 12px;
  }

  &.full-width {
    width: 100%;
  }

  &[color="white"] {
    color: white;
    border-color: white;

    &:focus,
    &:hover,
    &:active {
      color: white;
      border-color: white;
      background-color: $secondary-color;
    }
  }

  &[color="accepted"] {
    color: white;
    background: $secondary-color;
    border-color: $secondary-color;

    &:focus,
    &:hover,
    &:active {
      background-color: $secondary-color;
    }
  }

  .ant-btn-loading-icon {
    padding: 0;

    .anticon-loading {
      padding: 0 3px 0 0;
    }
  }
}

.trustben-button.ant-btn {
  border: 1px solid $primary-color;
  border-radius: 4px;
  font-family: $semi-bold-font;
  font-size: 14px;
  color: $primary-color;

  &.ant-btn-default:hover,
  &.ant-btn-default:active {
    color: $secondary-color;
    border: 1px solid $secondary-color;
  }

  span {
    padding-left: 10px;
    padding-right: 10px;
  }

  &.ant-btn-primary {
    background: $primary-color;
    color: $white-color;

    &:hover,
    &:active {
      background: $secondary-color;
      border-color: $secondary-color;
    }
  }

  &[disabled],
  &[disabled]:hover {
    color: $white-color;
    background-color: $disabled-button-color;
    border-color: $disabled-button-color;
  }

  &.ant-btn-link {
    border: none;
    font-weight: normal;
    font-size: 16px;
    padding: 0;
    outline: none;

    &:hover,
    &:active {
      color: $secondary-color;
    }

    span {
      padding: 0;

      &:hover {
        color: $secondary-color;
        text-decoration: 2px underline $primary-color;
      }
    }

    &:hover {
      color: $primary-color;
      background-color: $white-color;
    }
  }

  .ant-btn-loading-icon {
    padding: 0;

    .anticon-loading {
      padding: 0 3px 0 0;
    }
  }
}
