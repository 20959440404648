@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

.ben-choose-type-of-client-modal-container {
  margin: 5px 15px 30px 15px;
}

.ben-choose-type-of-client-modal-title {
  text-align: center;

  h1 {
    font-family: $semi-bold-font;
    color: $primary-color;
  }
}

.ben-choose-type-of-client-modal-buttons {
  @include flex($flexDirection: column);
  margin: 30px 130px 30px 130px;

  & > * {
    width: 100%;
    margin: 10px;
    height: 35px !important;
  }
}

@include breakpoint(md) {
  .ben-choose-type-of-client-modal-buttons {
    margin: 0;
  }
}
