@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-altquote-page-offers {
  .ben-altquote-page-panel-item {
    color: $gray-3-color;
    padding: 13px 0 0 24px;
    font-size: 15px;
  }
}

@include breakpoint(md) {
  .ben-altquote-page-offers {
    .ben-altquote-page-panel-item {
      font-size: 13px;
      padding-left: 0;
    }
  }
}
