@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";

.ben-content-container {
  @include flex();
  background: $white-color;
  flex-direction: column;
  padding: 20px 30px 32px 30px;
  width: 300px;
  min-height: 210px;

  h3 {
    margin: 0;
  }
}

@include breakpoint(md) {
  .ben-content-container {
    padding-top: 0;
    width: 100%;
    min-height: auto;

    h3 {
      padding-bottom: 8px;
    }

    .ben-card-text {
      padding-bottom: 8px;
    }
  }
}