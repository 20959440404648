@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

.ben-choose-client-modal-container {
  @include flex($flexDirection: column, $alignItems: center);
  margin: 5px 15px 30px 15px;
  margin-bottom: 10px;
}

.ben-choose-client-modal-title {
  text-align: center;

  h1 {
    font-family: $semi-bold-font;
    color: $primary-color;
  }
}

.ben-choose-client-modal {
  .ant-modal-footer {
    border: none;
    @include flex();
    margin: 0 28px;
    padding-bottom: 50px;

    .ant-btn-primary {
      width: 170px;
    }
  }
}

.ben-choose-client-modal-form {
  margin: 0 50px 0 50px !important;

  .ben-modal-form-item {
    label {
      font-weight: 400;
      margin-bottom: 25px;
      font-size: 13px;
    }
  }
}

.ben-choose-client-modal-content {
  width: 80%;
}

.ben-choose-client-modal-button-container {
  @include flex();
  width: 98.5%;
  margin-top: 40px;

  Button {
    margin: 3px 0 3px 0;
    margin-left: auto;
  }
}

.ben-update-client-profile-button:hover {
  background-color: inherit !important;
}

@include breakpoint(md) {
  .ben-choose-client-modal {
    .ant-modal-body {
      padding: 0;

      .ben-choose-client-modal-content {
        width: 100%;
      }
    }

    .ben-choose-client-modal-form {
      margin: 0;

      .ant-form-item-label {
        display: flex;
        justify-content: center;

        & > label {
          margin: 0;
          font-size: 15px;
        }
      }
    }
  }

  .ben-update-client-profile-button {
    white-space: normal;
  }

  .ant-modal-footer {
    margin: 0 !important;
  }
}
