.ant-notification {
  .ant-notification-notice-wrapper {
    border-radius: 2px;

    .ant-notification-notice-closable {
      .ant-notification-notice-message {
        padding-right: 24px;
      }
    }

    .ant-notification-notice {
      padding: 16px 24px;
    }
  }
}
