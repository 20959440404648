@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/breakpoints";

.ben-altquote-page-total-nav-container {
  color: $gray-3-color;
  font-family: $semi-bold-font;

  p {
    text-transform: uppercase;
  }
}

.ben-altquote-page-title-border-bottom {
  width: 74.5%;
  border-bottom: 1px solid $light-gray-color;
}

.ben-altquote-page-title-border-bottom.fund-view {
  width: 59%;
}

.ben-altquote-page-collapse-title {
  color: $secondary-color;
  font-family: $semi-bold-font;
  padding-left: 24px;
  padding-bottom: 10px;

  .ben-altquote-page-offer-options {
    margin-left: -24px;

    .ben-altquote-page-expand-all-button {
      color: $primary-color;
      cursor: pointer;
      font-size: 13px;
      line-height: 23px;
      margin-left: 10px;
    }
  }

  .ben-altquote-page-title-with-tooltip {
    text-align: center;

    .ben-tooltip {
      margin-left: 3px;
      font-size: 15px;
    }
  }
}

@include breakpoint(md) {
  .ben-altquote-page-title-border-bottom,
  .ben-altquote-page-title-border-bottom.fund-view {
    width: 100%;
  }

  .ben-altquote-page-collapse-title {
    width: 100%;
    font-size: 11px;
    min-height: 40px;

    .ben-altquote-page-offer-options {
      @include flex($flexDirection: column, $alignItems: flex-start);

      .ben-altquote-page-expand-all-button {
        margin-left: 0;
      }
    }

    .ben-altquote-page-title-with-tooltip {
      .ben-tooltip {
        margin-left: 0;
      }
    }
  }
}
