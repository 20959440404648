@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin transition {
  transition: 0.2s;
}

@mixin btn-reset {
  border: none;
  padding: 0;
  background: none;
}

@mixin flex($alignItems: center, $justifyContent: space-between, $flexDirection: row) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
  flex-direction: $flexDirection;
}

@mixin box-shadow {
  -webkit-box-shadow: 2px 2px 16px 0 rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 2px 2px 16px 0 rgba(34, 60, 80, 0.2);
  box-shadow: 2px 2px 16px 0 rgba(34, 60, 80, 0.2);
}

@mixin box-shadow-2 {
  -webkit-box-shadow: 0 2px 6px 0 rgba(55, 55, 55, 0.45);
  -moz-box-shadow: 0 2px 6px 0 rgba(55, 55, 55, 0.45);
  box-shadow: 0 2px 6px 0 rgba(55, 55, 55, 0.45);
}

@mixin box-shadow-3 {
  -webkit-box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.2);
  box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.2);
}

@mixin box-shadow-tabs {
  -webkit-box-shadow: 0 -4px 12px -6px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 -4px 12px -6px rgba(34, 60, 80, 0.2);
  box-shadow: 0 -4px 12px -6px rgba(34, 60, 80, 0.2);
}
