@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.custom-tooltip-container {
  font-family: $medium-font;
  padding: 10px;
  background-color: $white-color;
  border: 1px solid $gray-2-color;
  border-radius: 4px;

  p {
    margin: 0;
  }
}
