@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.add-chart-container {
  @include flex($flexDirection: column);

  .add-chart-title {
    font-family: $semi-bold-font;
    color: $primary-color;
    margin-bottom: 65px;
  }

  .add-chart-button.ant-btn {
    width: 170px;
    height: 170px;
    padding-top: 4px;
    flex-shrink: 0;
    margin-bottom: auto;

    .anticon {
      font-size: 24px;
    }
  }
}
