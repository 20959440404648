@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";

.ben-main-section {
  padding-bottom: 24px;

  h1 {
    font-size: 32px;
    font-weight: 300;
    line-height: 1;
    color: $dark-primary-color;
    margin: 0 0 27px;

    @include breakpoint(md) {
      font-size: 23px;
      margin-bottom: 20px;
    }

    .ant-btn {
      margin: 2px 10px 0 0;
      align-self: flex-start;
    }
  }

  .ben-main-section-header {
    display: flex;
    justify-content: space-between;
    padding-top: 17px;

    @include breakpoint(sm) {
      flex-direction: column-reverse;
      padding-top: 0;
    }

    h1 {
      @include breakpoint(md) {
        display: flex;
        margin-top: -3px;
      }
    }
  }

  &-bp {
    @include breakpoint(md) {
      padding-bottom: 60px;
    }
  }
}

.ben-main-section-btn-holder {
  @include breakpoint(sm) {
    padding-bottom: 23px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 18px;
  }

  .ant-btn {
    @include breakpoint(sm) {
      width: 100%;
    }
  }
}
