@import "common/assets/styles/base/colors";

.ben-icon-primary-color svg {
  fill: $primary-color;
}

.ben-icon-secondary-color svg {
  fill: $secondary-color;
}

.ben-icon-input-color svg {
  fill: $input-color;
}
