@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";

.ant-table-column-title {
  white-space: wrap;
}
.investment-analytics-table {
  .excluded {
    opacity: .35;
  }
}
.investment-filters-section .ant-btn-primary, .ant-input-group-addon {
  font-weight: bold;
  background: var(--primary-color)!important;
  &:hover {
    background: var(--secondary-color)!important;
  }
}
.alt-custody-asset-list-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    .buttons-container {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        align-items: center;
        .totalNav {
            font-weight: bolder;
            flex: 1;
            font-size: 16px;
        }
    }
}

.altcustody-asset-list-item {
  display: flex;
  width: 100%;
  align-items: flex-start;
  .title-row {
    display: flex;
    align-items: baseline;
    flex: 2;
    gap: 4px;
    .asset-title {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      font-family: $bold-font;
      color: $primary-color;
    }
  }
  .data-column {
    flex: 1;
    .column-title {
      padding: 0;
      margin: 0;
      font-family: $bold-font;
    }
    .column-text {
      padding: 0;
      margin: 0;
    }
  }

  .ben-application-status {
    text-transform: capitalize;
  }
}

@include breakpoint(md) {
  p {
    font-size: 13px;
  }
}

.portfolio-analytics-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .ben-charts-list-container {
    display: flex;
    flex-direction: row;
    gap: 16px;
    overflow: hidden;
    overflow-x: auto;
  }
}

.investment-filters-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  .row {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 16px;
  }
}