@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

.add-manually-container {
  @include flex();
  @include box-shadow-2();
  font-size: 14px;
  font-family: $bold-font;
  padding: 20px 4%;
  color: $white-color;
  background: $secondary-color;

  button {
    margin-left: 20px;
  }
}

@include breakpoint(md) {
  .add-manually-container {
    padding: 21px 4%;
  }
}
