.ben-set-document-password-form {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-form-item .ant-form-item-control {
        min-height: 0;
    }

    button:hover {
        background-color: inherit !important;
    }
}