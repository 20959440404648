@media (width > 1248px) {
  .ben-main-container, .ben-header {
    > .ben-container {
      padding-left: calc(24px + (100vw - 100%) / 2);
      padding-right: calc(24px - (100vw - 100%) / 2);
    }
  }
}

@media (width < 1248px) {
  .ben-main-container, .ben-header {
    > .ben-container {
      padding-right: calc(24px - (100vw - 100%));
    }
  }
}
