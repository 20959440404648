@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/helper/mixins";

.trustben-nav-link {
  color: $secondary-color;
  font-size: 16px;
  padding: 0 10px;

  &.mobile {
    color: $gray-color;
    font-weight: bold;
    font-size: 17px;
    padding: 10px 0;
  }

  &:hover {
    color: $primary-color;
  }
}

.trustben-nav-menu-button,
.trustben-nav-menu-button:focus {
  height: 63px !important;
  border-radius: 0 !important;
  font-size: 16px !important;
  margin-left: 10px;

  @include breakpoint(md) {
    width: 100%;
    margin-top: 10px;
  }

  &.primary,
  &.primary:hover {
    background: $primary-color !important;
    border: 1px solid $primary-color !important;

    span {
      color: $white-color;
    }
  }

  &.secondary,
  &.secondary:hover {
    background: $secondary-color !important;
    border: 1px solid $secondary-color !important;

    span {
      color: $white-color;
    }
  }
}

@include breakpoint(lg) {
  .trustben-nav-link:not(.mobile) {
    font-size: 13px;
  }

  .trustben-nav-menu-button:not(.mobile) {
    font-size: 13px !important;
    padding: 0;
  }
}

@include breakpoint(md) {
  .trustben-nav-menu-button {
    margin-left: 0px;
  }
}

.ant-drawer-header {
  padding: 16px 0 0 0 !important;
  border: none !important;

  .ant-drawer-header-title {
    margin-left: auto;
    flex: none !important;
  }
}

.ant-drawer-body {
  @include flex($flexDirection: column, $alignItems: flex-start, $justifyContent: stretch);
}
