@import "common/assets/styles/base/breakpoints";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.ben-form-footer {
  max-width: 630px;
  width: 100%;
  margin: 64px auto 0;

  .ben-form-footer-info {
    padding: 24px 0;
  }
}

@include breakpoint(md) {
  .ben-form-footer {
    margin-top: 48px;

    .ben-form-footer-info {
      padding: 16px 0;
    }
  }
}

.ben-divider {
  height: 2px;
  background: radial-gradient(circle, rgba(80, 135, 177, 0.4) 0%, rgba(252, 70, 107, 0) 100%);
}
