.add-chart-button-pdf {
  display: none;
}

.charts-pdf {
  flex-wrap: wrap;

  .chart-title {
    margin-bottom: -10px;
  }

  .ant-btn {
    display: none;
  }
}

// [data-table="pdf"] {
//   max-width: 100%;
//   table {
//     max-width: 100%;
//     td, .ant-table-column-title {
//       font-size: 11px;
//     }
//   }
//   .ant-table-column-sorter {
//     display: none;
//   }
// }
