@import "common/assets/styles/base/colors";

.ant-checkbox.ant-checkbox {
  &.ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $primary-color;
      border-color: $primary-color;
    }

    &.ant-checkbox-disabled {
      & + span {
        color: $gray-3-color;
      }

      .ant-checkbox-inner {
        background-color: $gray-3-color;
        border-color: $gray-3-color;
      }

      .ant-checkbox-inner::after {
        border-color: $white-color;
      }
    }
  }
}

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: $primary-color;
  border-color: $primary-color;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-inner {
  border-color: $primary-color;
}

.ant-checkbox-wrapper {
  .ant-checkbox + span {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: $primary-color;
  }
}
