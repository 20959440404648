@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/breakpoints";

.ben-add-liquidity-name-modal-container {
  @include flex($flexDirection: column, $alignItems: center);
  margin: 5px 15px 30px 15px;

  .ben-add-liquidity-name-modal-title {
    text-align: center;

    h1 {
      text-transform: uppercase;
      font-family: $semi-bold-font;
      color: $primary-color;
    }
  }
}

.ben-add-anonymous-client-name-form {
  width: 100%;

  .ben-add-anonymous-client-name-form-item {
    margin: 0 115px 0 20px;

    .ant-form-item-label {
      width: 38%;
      justify-content: flex-end;

      label {
        position: absolute;
        font-weight: 400;
        top: 0px;
        font-size: 13px;
      }
    }
  }

  .ben-add-anonymous-client-name-form-buttons {
    width: 47.5%;
    margin: 0 0 0 183px;
    .ant-form-item-control-input-content {
      @include flex();

      & > * {
        width: 45%;
      }
    }
  }
}

@include breakpoint(md) {
  .ben-add-anonymous-client-name-form-item {
    margin: 0 !important;

    .ant-form-item-label {
      justify-content: flex-start !important;
      margin-bottom: -15px;
    }
  }

  .ben-add-anonymous-client-name-form-buttons {
    margin: 0 !important;
    width: 100% !important;
  }
}
