@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/breakpoints";

.ben-altquote-page-wrapper {
  border-bottom: 1px solid $light-gray-color;

  .ben-altquote-page-stock-container {
    min-width: 365px;
    margin-left: 16px;

    button {
      margin-left: auto;
    }
  }

  .ben-proposals-page-stock-types-text {
    font-size: 13px;
    font-family: $bold-font;
    color: $gray-color;
  }
}

.ben-altquote-page-container {
  @include flex();
  flex-wrap: wrap;

  .ben-altquote-page-title-container {
    color: $gray-3-color;
    width: 100%;

    h2 {
      font-family: $semi-bold-font;
      color: inherit;
    }

    p {
      margin: 0;
    }
  }

  .ben-altquote-page-offers-container {
    display: flex;
    align-self: flex-end;

    &.ben-single-fund-view {
      display: none;
    }

    div {
      font-family: $bold-font;
      white-space: nowrap;
    }

    .ben-altquote-page-assets-button {
      width: 130px;
      text-align: center;
      border-bottom: 4px solid $light-gray-color;
      transition: 0.3s;
      cursor: pointer;

      &.ben-active {
        color: $primary-color;
        border-bottom: 4px solid $primary-color;
      }
    }
  }
}

.ben-altquote-page-bottom-info-message {
  color: $gray-3-color;
}

@include breakpoint(md) {
  .ben-altquote-page-wrapper {
    .ben-altquote-page-stock-container {
      margin: 15px 0 !important;
      font-size: 8px !important;
      width: 100% !important;
      min-width: 0;
    }
  }
}
