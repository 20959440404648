@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.ben-tooltip-content-container {
  padding: 10px 0;

  h5 {
    color: $gray-color;
    font-size: 13px;
  }

  ul {
    padding-left: 18px;
  }

  .tooltip-bold-text {
    font-size: 13px;
    font-family: $medium-font;
    color: $gray-color;
  }

  .tooltip-text {
    font-size: 13px;
    color: $gray-color;
  }
}
