@import "common/assets/styles/helper/mixins";
@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";

.ben-chart-container {
  @include flex($flexDirection: column);
  flex-shrink: 0;
  width: 230px;

  .chart-title {
    font-family: $semi-bold-font;
    color: $primary-color;
  }

  .recharts-wrapper * {
    outline: none;
  }
}
