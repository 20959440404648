@import "common/assets/styles/base/colors";
@import "common/assets/styles/base/fonts";
@import "common/assets/styles/base/breakpoints";

.ben-alt-quotes-content {
  .ben-alt-quote-item {
    display: flex;
    margin-bottom: 16px;

    div {
      cursor: pointer;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
    }

    .ben-request-formal-proposal-btn {
      margin-top: 5px;
      margin-left: auto;
      margin-right: 0;
    }

    .ben-alt-quote-name {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: bold;
      font-family: $bold-font;
      color: $primary-color;
    }
  }
}

@include breakpoint(md) {
  .ben-alt-quotes-content {
    .ben-alt-quote-item {
      display: block;

      .ben-alt-quote-name {
        margin-top: 24px;
      }

      p {
        font-size: 13px;
      }
    }
  }
}
