@import "common/assets/styles/base/colors";

.ant-input,
.ant-input-number {
  width: 100%;
  border: 1px solid $light-gray-color;
  border-radius: 0;
  font-size: 16px;

  &:hover {
    border-color: $primary-color;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.ant-input.small {
  height: 31px;
}

.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper {
  height: 39px;

  &.small {
    height: 31px;

    .ant-input-number-input-wrap,
    .ant-input-number-input {
      height: 100%;
    }
  }

  &.ben-small {
    height: 31px;
  }

  .ant-input {
    margin: 0;
    font-size: 16px !important;
  }
}

.ant-input-affix-wrapper {
  &.ben-search {
    padding-right: 4px !important;

    .ant-input {
      color: $gray-color;
      font-family: $main-font;
    }

    padding: 4px 8px 4px 11px;

    .ant-input-suffix {
      color: $input-color;

      &:hover {
        cursor: pointer !important;
      }
    }

    &:hover {
      border-color: $input-color;
    }

    &.ant-input-affix-wrapper-focused {
      border-color: $input-color;
      box-shadow: none;
    }

    span {
      font-size: 21px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.ant-input-number {
  &.ant-input-number-focused {
    box-shadow: none;
  }
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper:focus-within,
.ant-input-number-affix-wrapper:not(.ant-input-number-affix-wrapper-disabled):hover,
.ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper:focus-within,
.ant-input-number:focus,
.ant-input:focus,
.ant-input-affix-wrapper-focused,
.ant-input-number-affix-wrapper-focused {
  border-color: $input-color;
}

.ant-input-group-wrapper {
  &.ben-small {
    .ant-input-wrapper.ant-input-group {
      .ant-input-group-addon {
        border: none;
        background: none;
      }
      .ant-input-affix-wrapper {
        @extend .ben-small;
      }
    }
  }

  &.ben-search {
    .ant-input-wrapper.ant-input-group {
      .ant-input-affix-wrapper {
        @extend .ben-search;
      }
    }
  }
}

.ant-form-item {
  &.ben-input {
    .ant-form-item-label {
      min-height: 20px;

      label {
        font-size: 15px;
        font-family: $semi-bold-font;
        color: $secondary-color;
        line-height: 16px;
      }
    }

    .ant-form-item-control {
      line-height: normal;

      .ant-select-selector {
        height: 31px;
      }
    }
  }
}

.ant-form-item-control {
  .ant-picker {
    width: 100%;
    height: 100%;
  }
}

.ant-input-number-affix-wrapper {
  width: 100%;

  &:has(.ant-input-number-prefix:empty) {
    padding: 0;

    input {
      padding: 0 11px;
    }
  }

  .ant-input-number-prefix {
    margin: 0;
  }
}

.ant-input-textarea-affix-wrapper {
  .ant-input:disabled {
    color: inherit;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  -webkit-text-fill-color: inherit;
  -webkit-background-clip: text;
  transition: background-color 5000s ease-in-out 0s;
}
