// margin left
.ben-ml-0 {
  margin-left: 0 !important;
}

.ben-ml-1 {
  margin-left: 0.5rem !important;
}

.ben-ml-2 {
  margin-left: 1rem !important;
}

.ben-ml-3 {
  margin-left: 1.5rem !important;
}

.ben-ml-4 {
  margin-left: 2rem !important;
}

.ben-ml-5 {
  margin-left: 2.5rem !important;
}

// margin right
.ben-mr-0 {
  margin-right: 0 !important;
}

.ben-mr-1 {
  margin-right: 0.5rem !important;
}

.ben-mr-2 {
  margin-right: 1rem !important;
}

.ben-mr-3 {
  margin-right: 1.5rem !important;
}

.ben-mr-4 {
  margin-right: 2rem !important;
}

.ben-mr-5 {
  margin-right: 2.5rem !important;
}

// margin bottom
.ben-mb-0 {
  margin-bottom: 0 !important;
}

.ben-mb-1 {
  margin-bottom: 0.5rem !important;
}

.ben-mb-2 {
  margin-bottom: 1rem !important;
}

.ben-mb-3 {
  margin-bottom: 1.5rem !important;
}

.ben-mb-4 {
  margin-bottom: 2rem !important;
}

.ben-mb-5 {
  margin-bottom: 2.5rem !important;
}

// margin top
.ben-mt-1 {
  margin-top: 0.5rem !important;
}

.ben-mt-2 {
  margin-top: 1rem !important;
}

.ben-mt-3 {
  margin-top: 1.5rem !important;
}

.ben-mt-4 {
  margin-top: 2rem !important;
}

// padding right
.ben-pr-0 {
  padding-right: 0 !important;
}

.ben-pr-1 {
  padding-right: 0.5rem !important;
}

.ben-pr-2 {
  padding-right: 1rem !important;
}

.ben-pr-3 {
  padding-right: 1.5rem !important;
}

.ben-pr-4 {
  padding-right: 2rem !important;
}

.ben-pr-5 {
  padding-right: 2.5rem !important;
}

// padding left
.ben-pl-0 {
  padding-left: 0 !important;
}

.ben-pl-1 {
  padding-left: 0.5rem !important;
}

.ben-pl-2 {
  padding-left: 1rem !important;
}

.ben-pl-3 {
  padding-left: 1.5rem !important;
}

.ben-pl-4 {
  padding-left: 2rem !important;
}

.ben-pl-5 {
  padding-left: 2.5rem !important;
}

// paddint top
.ben-pt-0 {
  padding-top: 0 !important;
}

.ben-pt-1 {
  padding-top: 0.5rem !important;
}

.ben-pt-2 {
  padding-top: 1rem !important;
}

.ben-pt-3 {
  padding-top: 1.5rem !important;
}

.ben-pt-4 {
  padding-top: 2rem !important;
}

.ben-pt-5 {
  padding-top: 2.5rem !important;
}

// padding bottom
.ben-pb-0 {
  padding-bottom: 0 !important;
}

.ben-pb-1 {
  padding-bottom: 0.5rem !important;
}

.ben-pb-2 {
  padding-bottom: 1rem !important;
}

.ben-pb-3 {
  padding-bottom: 1.5rem !important;
}

.ben-pb-4 {
  padding-bottom: 2rem !important;
}

.ben-pb-5 {
  padding-bottom: 2.5rem !important;
}
