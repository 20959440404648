@import "common/assets/styles/base/colors";

.ben-table {
  max-height: 50vh;
  overflow-y: auto;

  .ant-table-tbody > tr > td {
    color: $primary-color;
  }

  .ben-table-row-active > td {
    background: rgba($color: #09819f, $alpha: 0.05);
  }

  .ben-table-row-active:hover > td:hover {
    background: rgba($color: #09819f, $alpha: 0.05);
  }
}
